<template>
    <v-container>
        <HeadbarPage :title="'Títulos > Cancelamento de Negociação em Massa'"></HeadbarPage>
        <UploadEmMassa
            @buscarTitulos="buscarTitulos"
            :title="'Cancelamento de Negociação em Massa'"
            :textAreaLabel="'Cole aqui os títulos que deseja solicitar o Cancelamento'"
            :textAreaHint="'Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5'"
            :instrucaoUpload="'Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para cancelamento'"
            :textAreaPlaceholder="'Cole aqui os títulos que deseja solicitar Cancelamento em massa.'"
            :dica="'Dica: o cancelamento de negociação pode ser solicitado somente para os títulos com os seguintes status: 320 Solicitado Negociação, 321 Enviado para Negociação, 325 Apontamento Negociação, 302 Em negociação.'"
        />
        <TableWithSelections 
            ref="tableWithSelections"
            :titulos="titulos"
            :totalTitulos="totalTitulos"
            :loading="loading"
            @selecionados="setSelecionados"
        />
        <CardConfirmacaoEnvioEmMassa 
            :selecionados="selecionados"
            :avisoRetorno="avisoRetorno"
            :loadingFiltros="loadingFiltros"
            :resultadoCheck="resultadoCheck"
            :titulosEnviadosCheck="titulosEnviadosCheck"
            :type="'Cancelamento de Negociação'"
            :useField="'id_titulo'"
            @enviar="cancelamentoMassa"
            @clearAll="clearAll"
        />
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
        
    </v-container>
</template>
<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
export default {
    name: 'CancelamentoEmMassaV2',
    components: {
        HeadbarPage: () => import('@/components/atoms/HeadbarPage'),
        UploadEmMassa: () => import('@/components/organisms/UploadEmMassa'),
        TableWithSelections: () => import('@/components/atoms/TableWithSelections'),
        CardConfirmacaoEnvioEmMassa: () => import('@/components/organisms/CardConfirmacaoEnvioEmMassa'),
    },
    data(){
        return{
            snackbar: false,
            colorSnackbar: '',
            mensagemSnackbar: '',
            filtros: {
                cd_status: '',
            },
            tituloService: new TituloService(Vue.http, this.$store),
            options: {
                page: 1,
                itemsPerPage: 300
            },
            titulos: [],
            totalTitulos: 0,
            loading: false,
            selecionados: [],
            avisoRetorno: [],
            loadingFiltros: false,
            resultadoCheck: false,
            titulosEnviadosCheck: true,
        }
    },
    methods: {
        setSelecionados(selecionados) {
            console.log(selecionados);
            this.selecionados = selecionados;
        },
        formatarDocumento() {
            var nu_identificacao_devedor = this.filtros
                .nu_identificacao_devedor;
            if (nu_identificacao_devedor != null) {
                nu_identificacao_devedor = nu_identificacao_devedor.match(
                    /\d/g
                );
                nu_identificacao_devedor = nu_identificacao_devedor.join('');
                return nu_identificacao_devedor;
            } else {
                return null;
            }
        },

        cancelamentoMassa(data){

            if(this.loadingFiltros){
                return;
            }
            this.loadingFiltros = true;

            let data_titulos = data.map((id_titulo)=> { return {"id_titulo": id_titulo}});
            let post_data = {
                "titulos": data_titulos           
            }

            this.tituloService.cancelaNegociacao(post_data).then(response => {
                this.titulosEnviadosCheck = false;
                this.resultadoCheck = true;
                this.titulosEnviadosCheck = false;
                this.loadingFiltros = false;
                
                let data_resp = response.body
                console.log(data_resp);
                console.log(Object.keys(data_resp['titulos']));
                console.log(this.selecionados)

                let retorno = []
                //adequando o retorno para o esperado pelo front
                Object.keys(data_resp['titulos']).forEach(key => {
                    key = parseInt(key);
                    let titulo = this.selecionados[key];

                    let titulo_status = typeof data_resp['titulos'][key]['success'] != 'undefined' ? "success" : "fail";
                    let titulo_message = typeof data_resp['titulos'][key]['success'] != 'undefined' ? data_resp['titulos'][key]['success'] : data_resp['titulos'][key]['error'];
                    let titulo_codigo = titulo_status == "success" ? 200 : 400;

                    retorno.push({
                        "id": titulo["id_titulo"],
                        "titulo": titulo["ds_nosso_numero"],
                        "retorno": {
                            "status": titulo_status,
                            "mensagem": titulo_message[0],
                            "codigo": titulo_codigo
                        },
                    })
                })

                this.avisoRetorno = retorno;
            }).catch(error => {
                this.snackbar = true;
                this.colorSnackbar = 'error';
                this.mensagemSnackbar = 'Erro ao enviar títulos para cancelamento';
                console.log(error);
            });

        },
        
        buscarTitulos(filtros) {
            this.filtros = filtros;
            this.loading = true;

            this.$refs['tableWithSelections'].resetSelecionados();
            this.selecionados = [];

            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            this.situacoesTitulo = this.$store.getters.situacoesTitulo;
            this.situacoesTitulo.push({
                cd_status: 99999,
                nm_status: 'EM REVISÃO'
            });
            this.filtros.cd_status = '320,321,325,302',
            this.filtros.nu_identificacao_devedor = this.formatarDocumento();
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;
                try {
                    let service = new TituloService(Vue.http, this.$store);

                    service
                        .filtrarTitulos(this.filtros, page, itemsPerPage)
                        .then(
                            response => {
                                response.status;
                                response.statusText;
                                response.headers.get('Expires');
                                this.exportActive = false;
                                const {
                                    titulos,
                                    numero_linhas: total
                                } = response.body;

                                this.loadingFiltros = false;
                                this.loading = false;
                                console.log(titulos, total)
                                this.titulos = titulos;
                                this.totalTitulos = total;
                                resolve();
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.mensagem = error;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },

        clearAll() {
            this.titulos = []
            this.totalTitulos = 0
            this.loading = false
            this.selecionados = []
            this.avisoRetorno = []
            this.resultadoCheck = false,
            this.titulosEnviadosCheck = true
            this.buscarTitulos(this.filtros)
        }
    }
}
</script>